<template>
  <div>
    <head-layout
      head-title="机构分组"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
    >
    </head-layout>

    <grid-head-layout
      ref="searchForm"
      v-model="searchForm"
      :search-columns="searchColumns"
      @grid-head-search="searchChange"
      @grid-head-empty="searchReset"
    />

    <grid-layout
      ref="gridLayout"
      :searchColumns="searchColumns"
      :tableOptions="tableOptions"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      :data-total="page.total"
      :tableData="tableData"
      :page="page"
      @page-size-change="onLoad"
      @page-current-change="onLoad"
      @page-refresh-change="onLoad"
      @row-edit="rowEdit"
      @row-remove="rowRemove"
    ></grid-layout>

  </div>
</template>

<script>
import { DelDeptGroup, GettrainingDeptGroup } from "@/api/safe";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

export default {
  components: {
    HeadLayout,
    GridLayout,
  },
  props: {},
  data() {
    return {
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      tableData: [],

      headBtnOptions: [
        {
          label: "新增",
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],

      query: {},
      searchForm: {}, //搜索框对应数据
      searchColumns: [
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "分组名称",
          prop: "groupName",
          span: 4,
        },
        {
          placeholder: this.$t("cip.cmn.rule.inputWarning") + "分组编码",
          prop: "groupCode",
          span: 4,
        },
      ],

      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "row-edit",
          remark: "view",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "row-remove",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],

      tableOptions: {
        selection: false,
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: "分组名称",
            prop: "groupName",
            cell: true,
            align: 'left',
            overHidden: true,
          },
          {
            label: "分组编码",
            prop: "groupCode",
            align: 'left',
            overHidden: true,
          },
        ],
      },
    };
  },

  methods: {

    // 查询重置
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    // 查询按钮
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },

    // 刷新方法
    onLoad(page, params = {}) {
      this.page = page;
      params = {
        currentPage: page.currentPage,
        pageSize: page.pageSize,
      };
      this.tableLoading = true;
      GettrainingDeptGroup(Object.assign(params, this.query)).then((res) => {
        const data = res.data.data;
        page.total = data.total;
        this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },

    //编辑
    rowEdit(row) {
      this.$router.push({
        path: "/business/safetyenment/train/DeptGroupAdd",
        query: {
          type: "edit",
          id: row.id,
        },
      });
    },

    // 新增
    headAdd() {
      this.$router.push({
        path: "/business/safetyenment/train/DeptGroupAdd",
        query: {
          type: "add",
        },
      });
    },

    //行删除
    rowRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
      .then(() => {
        return DelDeptGroup(row.id);
      })
      .then(() => {
        this.onLoad(this.page);
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
      });
    },

  },
  mounted() {
    this.onLoad(this.page);
  },
};
</script>
<style scoped></style>
